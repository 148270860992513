<template>
  <div class="loginPage">
    <div class="image"></div>
    <div class="login">
      <h2 class="loginTitle">DDC IoT Platform</h2>
      <div class="error">
        <h4>{{ error }}</h4>
      </div>
      <div class="loginContent">
        <label class="input1" for="username">Username: </label>
        <br />
        <input
          class="inputBox1"
          v-model="username"
          type="text"
          name="username"
          @keydown="isEnter"
        />
        <br />
        <br />
        <label class="input2" for="Password">Password:</label>
        <br />
        <div class="passwordLine">
          <input
            class="inputBox2"
            id="passwordInput"
            v-model="password"
            type="password"
            @keydown="isEnter"
          />
          <button @click="showPassword" class="eyeButton">
            <i class="far fa-eye" id="eyeIcon"></i>
            <i class="far fa-eye-slash" id="slashedEye"></i>
          </button>
        </div>
        <br />
        <br />
        <button
          class="btn btn-success"
          type="submit"
          @click="login"
          id="loginButton"
        >
          Login
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import App from "@/App.vue";
import ddcIotAuthProvider from "../utils/ddcIotAuthProvider.js";
const apiUrl = process.env.VUE_APP_API_URL || "https://iot.ddc.sze.hu/external_api";
const authProvider = ddcIotAuthProvider(apiUrl);

export default {
  data() {
    return {
      username: null,
      password: null,
      error: null,
      deviceCount: 0,
      domainCount: 0,
      userCount: 0,
    };
  },

  methods: {
    login() {
      //console.log(this.username, this.password);
      localStorage.setItem("theme", "");

      authProvider
        .login(this.username, this.password)
        .then(() => {
          App.loggedIn = true;
          localStorage.setItem("username", this.username);
          App.user = localStorage.getItem("username");

          this.$router.push("/");

          //console.log(App.loggedIn);
        })
        .catch((err) => {
          if(err=="Error: Request failed with status code 500"){
            this.error = "Invalid Username or Password. Please try again!"
          }
          else{

          this.error = err + ". Please try again!";
          }
        });
    },

    isEnter() {
      if (event.keyCode == 13) {
        document.getElementById("loginButton").click();
      }
    },

    showPassword() {
      if (document.getElementById("passwordInput").type == "password") {
        document.getElementById("passwordInput").type = "text";
        document.getElementById("eyeIcon").style.display = "none";
        document.getElementById("slashedEye").style.display = "block";
      } else if (document.getElementById("passwordInput").type == "text") {
        document.getElementById("passwordInput").type = "password";
        document.getElementById("eyeIcon").style.display = "block";
        document.getElementById("slashedEye").style.display = "none";
      }
    },
  },
};
</script>

<style>
.image {
  background-image: url("../assets/login.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  z-index: 2;
  position: absolute;
}
.loginPage {
  background-color: #20262e;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}
.login {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  margin: auto;
  opacity: 1;
  height: 60vh;
  min-height: 400px;
  position: relative;
  min-width: 350px;
  width: 30vw;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.78);
  font-size: 1rem;
  z-index: 2;
  top: 25vh;
}

.input1 {
  width: 100%;
  text-align: center;
  top: 20%;
  position: relative;
}
.input2 {
  width: 100%;
  text-align: center;
  top: 20%;
  position: relative;
}

.loginTitle {
  text-align: center;
  top: 20%;
  position: relative;
}

.inputBox1 {
  display: block;
  width: 12vw;
  min-width: 200px;
  height: 35px;
  margin: auto;
  border-radius: 5px;
  border:0px;
}

.passwordLine{
  margin:auto;
  display: block;
  min-width: 200px;
  width: 12vw;
}
.inputBox2 {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border:0px;
}
.inputBox1:focus {
  outline: 0;
}
.inputBox2:focus {
  outline: 0;
}
#eyeIcon {
  height: 35px;
}

#slashedEye {
  display: none;
  height: 35px;
}

.eyeButton {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: black;
  position: relative;
  bottom: 35px;
  float: right
}
.loginContent {
  margin: auto;
  position:relative;
  top:20%;
}

#loginButton {
  margin:auto;
  position: relative;
  border-radius: 16px;
  font-size: 1rem;
  display: block;
  width: 120px;
  border:0;
  float:unset;


}

#loginButton:hover {
  opacity: 0.8;
}

.error {
  top: 0vh;
  left: 0px;
  position: absolute;
  text-align: center;
  color: rgb(255, 75, 75);
}
.loginPage{
   overflow-y: visible;
   overflow-x:hidden;
 }

@media (max-width: 450px) {
  .login {
    top: 12vh;
    min-width: 90%;
    left: 0px;
  }
 .loginPage{
   overflow-y: visible;
   overflow-x:hidden;
 }
  .loginTitle {
    text-align: center;
  }
  #loginButton {
    top: 60%;
    font-size: 100%;
  }
  .inputBox1 {
    top: 35%;
    width: 60%;
  }

  .inputBox2 {
    top: 50%;
  }

  .input1 {
    top: 28%;
    font-size: 100%;
  }
  .input2 {
    top: 44%;
    font-size: 100%;
  }

  .eyeButton {
    border: 0;
    font-size: 100%;
  }
}

</style>
